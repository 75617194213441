.container-fluid {
  justify-content: right !important;
  /* min-height: 80px !important; */
  padding: 0 15px !important;
}

.navbar-toggler {
  font-size: 1.875rem !important;
}

@media (max-width: 991.9px) {
  .navbar-collapse {
    width: 100%;
    float: none;
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    border: 1px solid;
    border-top: 0;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: #ed005c;
  }
}
