.wrap {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 40px;
  background-color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.padding-horizontal-15 {
  padding: 0 15px;
}

.primary-title {
  font-family: Philosopher;
  color: #ed005c;
  margin-top: 0;
  font-size: 35px;
  font-weight: 600 !important;
  line-height: 1.3;
  z-index: 4;
  position: relative;
  letter-spacing: 1px;
}

.slim-title {
  display: block;
  padding-bottom: 25px;
  position: relative;
  font-family: Bilbo Swash Caps;
  font-size: 26px;
  font-weight: 400;
  color: #191919;
}

.slim-title::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-right: 10px;
}

.slim-title::after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-left: 10px;
}

.info-boxes {
  border: 1px solid #7a7a7a;
  display: flex;
  flex-direction: row;
  padding: 7px;
  margin-bottom: 28px;
  min-height: 60px;
  max-width: 555px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: #ffffff;
  border-radius: 10px;
}

.info-box-icon {
  text-align: center;
  width: 46px;
  height: 46px;
  font-size: 25px;
  border-radius: 50%;
  background-color: #7a7a7a;
  line-height: 42px;
}

.info-boxes-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
}

.info-title {
  color: #ed005c !important;
  text-transform: uppercase;
  font-family: Philosopher;
  font-weight: 500;
}

.info-content {
  color: #646464;
  font-weight: bold;
}

.pricing-wrap {
  padding-bottom: 40px;
}

.pricing-container {
  max-width: 400px;
  margin: auto;
}

.pricing-table {
  background-color: #f5f5f5;
  margin-bottom: 30px !important;
}

.pricing-media {
  position: relative;
  z-index: 1;
  margin-top: -2px;
}

.pricing-media:before {
  content: ' ';
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 350px;
  -moz-border-radius: 350px;
  border-radius: 350px;
  -webkit-box-shadow: 0 0 0 2px #c7d5d3 inset, 0 0 0 1px #c7d5d3;
  -moz-box-shadow: 0 0 0 2px #c7d5d3 inset, 0 0 0 1px #c7d5d3;
  box-shadow: 0 0 0 2px #c7d5d3 inset, 0 0 0 1px #c7d5d3;
}

.pricing-table {
  position: relative;
  z-index: 1;
  border: 1px solid #c7d5d3;
  -webkit-border-top-left-radius: 350px;
  -moz-border-top-left-radius: 350px;
  border-top-left-radius: 350px;
  -webkit-border-top-right-radius: 350px;
  -moz-border-top-right-radius: 350px;
  border-top-right-radius: 350px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-bottom-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -moz-border-bottom-right-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.pricing-table ul {
  padding: 0 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.pricing-table:not(.active):hover {
  margin-top: -15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.pricing-table:not(.active):hover ul li {
  padding: 5px 0;
}

.pricing-table ul li {
  padding: 3px 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.pricing-table ul li:nth-child(2n + 1) {
  background-color: #f9fbf9;
}

.pricing-media img {
  width: 100%;
}

.cost-services {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 40px;
}

.sep-lane {
  position: relative;
  z-index: 1;
  line-height: 0;
  width: 100%;
  display: inline-block;

  span {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -moz-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -ms-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    background-color: #fff;
  }
}

.sep-lane-box {
  background-color: #f0f7f2;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  padding: 70px 15px;
}

.footer-img {
  padding: 20px 0;
}

.img-circle {
  border-radius: 50%;
  margin: 0 auto;
}

// Responsive
@media screen and (min-width: 992px) {
  .col-md-2 {
    width: 16.66666667%;
    height: 50px;
    float: left;
  }

  .img-circle {
    width: 33.33333333%;
    float: left;
  }
}

// Responsive
@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}
