.wrap {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 70px;
  background-color: #ffffff;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
}

.primary-title {
  font-family: Philosopher;
  color: #ed005c;
  margin-top: 0;
  font-size: 35px;
  font-weight: 600 !important;
  line-height: 1.3;
  z-index: 4;
  position: relative;
  letter-spacing: 1px;
}

.slim-title {
  display: block;
  position: relative;
  font-family: Bilbo Swash Caps;
  font-size: 26px;
  font-weight: 400;
  color: #191919;
  padding-bottom: 25px;
}

.slim-title::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-right: 10px;
}

.slim-title::after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-left: 10px;
}

.underline-title {
  padding-bottom: 15px;
  color: #ed005c;
  font-size: 24px;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-family: Philosopher;
  font-weight: 500;
}

.contact-wrap {
  margin-top: 28px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.contact-container {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.map-wrap {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.map-container {
  height: 350px;
  border: 3px solid #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.map-title {
  text-align: left;
}

.map-container iframe {
  width: 100%;
  height: 100%;
}

// Responsive
@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }

  .contact-wrap {
    display: flex;
  }

  .contact-container {
    width: 33.333333333%;
  }

  .map-wrap {
    width: 66.66666667%;
  }
}

@media (min-width: 992px) and (max-width: 1199.9px) {
  .container {
    width: 970px;
  }

  .contact-wrap {
    display: flex;
  }

  .contact-container {
    width: 33.333333333%;
  }

  .map-wrap {
    width: 66.66666667%;
  }
}

@media (min-width: 768px) and (max-width: 991.9px) {
  .container {
    width: 750px;
  }

  .contact-wrap {
    display: flex;
  }

  .contact-container {
    width: 33.333333333%;
  }

  .map-wrap {
    width: 66.66666667%;
  }
}
