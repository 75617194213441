.wrap {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 40px;
  background-color: #ffffff;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
}

.primary-title {
  font-family: Philosopher;
  color: #ed005c;
  margin-top: 0;
  font-size: 35px;
  font-weight: 600 !important;
  line-height: 1.3;
  z-index: 4;
  position: relative;
  letter-spacing: 1px;
}

.slim-title {
  display: block;
  position: relative;
  font-family: Bilbo Swash Caps;
  font-size: 26px;
  font-weight: 400;
  color: #191919;
  padding-bottom: 25px;
}

.slim-title::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-right: 10px;
}

.slim-title::after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-left: 10px;
}

.sep-lane {
  position: relative;
  z-index: 1;
  line-height: 0;
  width: 100%;
  display: inline-block;

  span {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -moz-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -ms-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    background-color: #fff;
  }
}

.sep-lane-box {
  background-color: #f0f7f2;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  padding: 40px 0;
}

.gallery-images {
  padding-bottom: 40px;
  padding-top: 20px;
}

.underline {
  color: #ed005c !important;
  text-decoration-line: underline;
}

// Responsive
@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 970px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 750px;
  }
}
