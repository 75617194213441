.page-section {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 40px;
  padding-bottom: 70px;
  background-color: #ffffff;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.policy-header {
  text-align: center;
  margin-bottom: 25px;
}

.primary-title {
  font-family: Philosopher;
  color: #ed005c;
  margin-top: 0;
  font-size: 35px;
  font-weight: 600 !important;
  line-height: 1.3;
  z-index: 4;
  position: relative;
  letter-spacing: 1px;
}

.slim-title {
  display: block;
  position: relative;
  font-family: Bilbo Swash Caps;
  font-size: 26px;
  font-weight: 400;
  color: #191919;
}

.slim-title::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-right: 10px;
}

.slim-title::after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-left: 10px;
}

.margin-top-20 {
  font-size: 17px;
  text-align: left;
  margin-top: 15px !important;
  margin-bottom: 8px !important;
}

.text-highlight {
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px !important;
  font-style: italic;
  color: #ed005c !important;
}

// Responsive
@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
