@import 'normalize.css';
@import '@coreui/coreui/dist/css/coreui.min.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,300,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Philosopher:400,400italic,700,700italic&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bilbo+Swash+Caps&display=swap');

:root {
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Work Sans', sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

a {
  text-decoration: none;
}
