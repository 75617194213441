.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.banner {
  border: 1px solid #c7d5d3;
  padding: 10px;
  border-radius: 10px;
  display: block;
  margin: auto;
}

.summary {
  padding: 40px 0 30px 0;
  margin: auto;
}

.info-boxes {
  border: 1px solid #7a7a7a;
  display: flex;
  flex-direction: row;
  padding: 7px;
  margin-bottom: 20px;
  min-height: 60px;
  max-width: 555px;
  margin-right: auto;
  margin-left: auto;
  color: #ffffff;
  border-radius: 10px;
}

.info-box-icon {
  text-align: center;
  width: 46px;
  height: 46px;
  font-size: 25px;
  border-radius: 50%;
  background-color: #7a7a7a;
  line-height: 42px;
}

.info-boxes-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
}

.info-title {
  color: #ed005c !important;
  text-transform: uppercase;
  font-family: Philosopher;
  font-weight: 500;
}

.info-content {
  color: #646464;
  font-weight: bold;
}

.primary-title {
  font-family: Philosopher;
  color: #ed005c;
  margin-top: 0;
  font-size: 35px;
  font-weight: 600 !important;
  line-height: 1.3;
  z-index: 4;
  position: relative;
  letter-spacing: 1px;
}

.slim-title {
  display: block;
  position: relative;
  font-family: Bilbo Swash Caps;
  font-size: 26px;
  font-weight: 400;
  color: #191919;
}

.slim-title::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-right: 10px;
}

.slim-title::after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-left: 10px;
}

.left-interview {
  padding: 0 15px;
  text-align: center;

  .interview-content {
    .paragraph-first {
      display: flex;
    }

    .circle-letter {
      display: inline-block;
      vertical-align: top;
      font-family: Philosopher;
      font-weight: 500;
      text-align: center;
      color: #ffffff !important;
      font-size: 34px;
      width: 45px;
      height: 45px;
      line-height: 45px;
      background-color: #c7d5d3;
      border-radius: 30px;
      margin-right: 5px;
      margin-top: 5px;
    }

    p {
      text-align: left;
    }
  }
}

.right-interview {
  padding: 0 15px;

  .interview-image {
    width: 100%;
    border: 1px solid #c7d5d3;
    padding: 10px;
    border-radius: 10px;
    display: block;
    margin: auto;
  }
}

.extra-banner {
  display: block;
  margin: 40px auto 0 auto;
}

.sep-lane {
  position: relative;
  z-index: 1;
  line-height: 0;
  width: 100%;
  display: inline-block;

  span {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -moz-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -ms-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    background-color: #fff;
  }
}

.sep-lane-box {
  background-color: #f0f7f2;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  padding: 40px 0;

  .slim-title {
    display: block;
    position: relative;
    font-family: Bilbo Swash Caps;
    font-size: 26px;
    font-weight: 400;
    color: #191919;
  }

  .slim-title::before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    top: 50%;
    width: 30px;
    height: 1px;
    background: #9e9f9f;
    margin-right: 10px;
  }

  .slim-title::after {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    top: 50%;
    width: 30px;
    height: 1px;
    background: #9e9f9f;
    margin-left: 10px;
  }

  .wrap-questions {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    margin: auto;

    .question-item {
      width: 100%;
      padding: 0 15px;
      margin-bottom: 30px;

      .question-symbol {
        padding-bottom: 15px;

        img {
          max-height: 75px;
        }
      }
    }
  }
}

.underline-title {
  padding-bottom: 15px;
  color: #ed005c;
  font-size: 24px;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-family: Philosopher;
  font-weight: 500;
}

.underline-title::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -12px;
  z-index: 1;
  width: 24px;
  height: 2px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #ed005c;
}

.wrap-services {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  padding: 40px 0 40px;
  background-color: #ffffff;
}

.header-service {
  text-align: center;
  .interview-title {
    font-family: Philosopher;
    color: #ed005c;
    margin-top: 0;
    font-size: 35px;
    font-weight: 600 !important;
    line-height: 1.3;
    z-index: 4;
    position: relative;
    letter-spacing: 1px;
  }

  .slim-title {
    display: block;
    position: relative;
    font-family: Bilbo Swash Caps;
    font-size: 26px;
    font-weight: 400;
    color: #191919;
    padding-bottom: 15px;
  }

  .slim-title::before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    top: 50%;
    width: 30px;
    height: 1px;
    background: #9e9f9f;
    margin-right: 10px;
  }

  .slim-title::after {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    top: 50%;
    width: 30px;
    height: 1px;
    background: #9e9f9f;
    margin-left: 10px;
  }
}

.services {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding-top: 25px;
  margin: auto;
}

.service-item {
  padding: 0 15px;
  text-align: center;
  margin: 0 auto 30px auto;
  max-width: 370px;
}

.item-image {
  position: relative;
  margin-bottom: 5px;
  img {
    border-radius: 10px;
  }
}

.item-image:before {
  content: ' ';
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.6) inset;
}

.card-content {
  margin-top: 5px;
}

.footer-service {
  margin-top: 30px;
  text-align: center;
}

.button {
  display: inline-block;
  position: relative;
  padding: 6px 10px;
  color: #ffffff;
  border: 1px solid;
  font-size: 18px;
  font-family: Philosopher;
  line-height: 1.375;
  text-decoration: none;
  letter-spacing: 1px;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s;
  background-color: #ed005c;
  border-color: #ed005c;
}

.button:hover {
  color: #ed005c;
  background-color: transparent;
}

// Responsive
@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }

  .wrap-interview {
    display: flex;
    flex-direction: row;
  }

  .left-interview,
  .right-interview {
    width: 50%;
  }

  .wrap-questions {
    flex-direction: row !important;
  }

  .service-item {
    width: 33.333333333%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 970px;
  }

  .wrap-interview {
    display: flex;
    flex-direction: row;
  }

  .left-interview,
  .right-interview {
    width: 50%;
  }

  .wrap-questions {
    flex-direction: row !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 750px;
  }

  .wrap-questions {
    flex-direction: column !important;
  }

  .question-item {
    width: 100% !important;
  }

  .wrap-interview {
    display: flex;
    flex-direction: column;
  }

  .service-item {
    width: 50% !important;
  }

  .service-item:nth-child(3) {
    width: 100% !important;
    text-align: center;
  }
}
