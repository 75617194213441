.wrap {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 40px 15px;
}

.primary-title {
  font-family: Philosopher;
  color: #ed005c;
  margin-top: 0;
  font-size: 35px;
  font-weight: 600 !important;
  text-align: center;
  line-height: 1.3;
  z-index: 4;
  position: relative;
  letter-spacing: 1px;
}

.slim-title {
  display: block;
  position: relative;
  text-align: center;
  font-family: Bilbo Swash Caps;
  font-size: 26px;
  font-weight: 400;
  color: #191919;
}

.slim-title::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-right: 10px;
}

.slim-title::after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #9e9f9f;
  margin-left: 10px;
}

.interview-wrap {
  margin-top: 25px;
}

.store-image {
  padding: 0 15px;
  margin-bottom: 20px;
}

.interview-content {
  padding: 0 15px;

  p {
    margin-bottom: 1.3em;
  }
}

.bold {
  font-weight: bold;
}

.img-circle {
  border-radius: 50%;
}

.img-border {
  border: 1px solid #c7d5d3;
  padding: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: block;
  margin: auto;
}

.col-md-6 {
  position: relative;
  min-height: 1px;
  padding: 10px;
}

.sep-lane {
  position: relative;
  z-index: 1;
  line-height: 0;
  width: 100%;
  display: inline-block;

  span {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -moz-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    -ms-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    background-color: #fff;
  }
}

.sep-lane-box {
  background-color: #f0f7f2;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  padding: 40px 0;
}

.facebook-page {
  margin-bottom: 30px;
  text-align: center;
}

// Responsive

@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .container {
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    width: 970px;
  }

  .interview-wrap {
    display: flex;
  }

  .store-image,
  .interview-content {
    width: 50%;
  }

  .interview-images {
    display: flex;
  }

  .col-md-6 {
    width: 50%;
  }
}
