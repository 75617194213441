.wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

.footer-rect {
  position: absolute;
  z-index: 20;
  left: 50%;
  top: -56px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 107px;
  margin-bottom: -8px;
}

.center-icon {
  max-width: 107px;
  margin-bottom: -8px;
  z-index: 20;
}

.footer-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0;
  background-color: #556359;
  color: #ffffff;
}

.container {
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  width: 1170px;
  margin: 0 auto;
}

.footer-item,
.footer-links {
  flex: 1;
  padding: 0 15px;
  text-align: center;
}

.footer-title {
  font-family: Philosopher;
  color: #ed005c;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.footer-title-small {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #ed005c;
  font-size: 18px;
  text-transform: uppercase;
  font-family: Philosopher;
  font-weight: 500;
}

.page-link {
  color: #ffffff;
  line-height: 2;
}

.contact-methods {
  color: #ffffff;
}

// Responsive
@media screen and (min-width: 1200px) {
  .container {
    width: 1170px;
    display: flex !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 970px;
    display: flex !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .container {
    width: 750px;
  }

  .footer-item {
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 4px solid #c7d5d3;
  }
}

@media screen and (max-width: 991.9px) {
  .footer-item {
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 4px solid #c7d5d3;
  }
}
