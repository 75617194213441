.wrap {
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.93);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.title {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #141414 !important;
  margin: auto;
  padding: 0 7px !important;
}

.logo {
  padding: 0 40px;
}

.logo-mob {
  padding: 0 15px;
}

.active {
  color: red !important;
}

@media (min-width: 992px) {
  .sep {
    margin: auto;
    padding: 0 7px;
    color: #e0e8e7;
  }

  .logo-mob {
    display: none !important;
  }

  .wrap {
    position: fixed;
    top: 0px;
  }
}

@media (max-width: 991.9px) {
  .sep {
    display: none;
  }

  .header-mob {
    display: flex;
    width: 100%;
    height: 80px;
    padding-right: 15px;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    display: none !important;
  }

  .title {
    display: block;
    width: 94%;
    text-align: center;
    height: 50px !important;
    line-height: 50px !important;
    position: relative;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ed005c !important;
    letter-spacing: 1px;
    cursor: pointer;
  }

  .title:last-child {
    border-bottom: none !important;
  }
}
