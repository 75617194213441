.wrap {
  width: 100%;
}

.container {
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

li {
  list-style-type: none;
}

@media (min-width: 992px) {
  .container {
    margin-top: 80px;
  }
}
